import React, { Component } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { CFade } from "@coreui/react";
import Lottie from "lottie-react";
import AnimationData from "./assets/loader.json";
import { createTheme, ThemeProvider } from "@mui/material";

export const host = window.location.host;
export const GURUDHARMA_URL = process.env.REACT_APP_GURUDHARMA_URL;

export const Loading = () => {
  return (
    <CFade className="w-full h-full flex flex-col items-center justify-center bg-white  absolute z-10">
      <Lottie animationData={AnimationData} className="lottie-animation" />
      <h3 className="title-loader">LOADING</h3>
      <img
        src="./assets/img/nisha.png"
        alt="background-loading"
        className="img-background"
      />
    </CFade>
  );
};

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));

const client = new QueryClient();
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3399FE",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F9B115",
    },
    shadows: ["none"],
  },
});

class App extends Component {
  componentDidMount() {
    document.title =
      host === GURUDHARMA_URL
        ? "Gurudharma - Game Master Tool"
        : "Lokapala - Game Master Tools";
  }

  render() {
    return (
      <QueryClientProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Toaster
              containerStyle={{
                top: 100,
                left: 300,
                bottom: 20,
                right: 20,
              }}
            />
            <React.Suspense fallback={<Loading />}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />

                {/* First load */}
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    );
  }
}

export default App;
